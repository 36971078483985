<template>
  <div>
    <a-card class="add-order" title="新建订单">
      <div>
        <a-row>
          <a-col class="input-line" :span="24">
            <span class="input-line-title">取货方式：</span>
            <a-radio-group v-model="formState.order_type" @change="onChangeOrderType">
              <a-radio :value="2">配送</a-radio>
              <a-radio :value="1">自提</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <!-- 总部下单：科技公司、可以第三方 -->
        <template v-if="!isCityManage">
          <!-- <a-row class="mt-4">
            <a-col class="input-line" :span="24">
              <span class="input-line-title">收款账户：</span>
              <div>科技公司<span class="ml-2" style="color:#aaa;">（货到付款除外）</span></div>
            </a-col>
          </a-row> -->
          <a-row class="mt-4">
            <a-col class="input-line mb-3 flex" :span="24" style="align-items: flex-start;">
              <span class="input-line-title" style="line-height:32px;">订单来源：</span>
              <a-radio-group v-model="isOnAccount" class="flex-1" @change="updateOffAmount">
                <div class="flex items-center" style="height:32px;">
                  <a-radio :value="1">第三方平台挂账（科技公司已收款）</a-radio>
                  <template v-if="isOnAccount == 1">
                    <a-select v-model="subOnAccountType" placeholder="请选择挂账平台"
                        class="ml-2" style="width:150px;">
                      <a-select-option
                        v-for="item in subOnAccountPayTypeList"
                        :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                    <a-input class="ml-2 flex-1" placeholder="第三方平台的流水号"
                      v-model="artificial_pay_flow_no"></a-input>
                  </template>
                </div>
                <div class="mt-2">
                  <!-- <a-radio :value="2">非第三方平台（先下单、后选支付方式）</a-radio> -->
                  <a-radio :value="2" :disabled="formState.order_type==1">非第三方平台（货到付款）</a-radio>
                </div>
              </a-radio-group>
            </a-col>
          </a-row>
        </template>
        <!-- 城市下单：城市财务、不可以第三方、但可以大客户 -->
        <template v-if="isCityManage">
          <a-row class="mt-4">
            <a-col class="input-line" :span="24">
              <span class="input-line-title">大客户订单：</span>
              <a-checkbox v-model="isBigEnterprise">大客户订单</a-checkbox>
              <template v-if="isBigEnterprise">
                <a-input class="ml-2" style="width:150px;" placeholder="客户名称"
                    v-model="formState.place_order_enterprise_name"></a-input>
                <span class="ml-2" style="color:#aaa;">（“线上订单流水”的导出 会包含客户名称，方便和大客户对账）</span>
              </template>
            </a-col>
          </a-row>
          <a-row class="mt-4">
            <a-col class="input-line" :span="24">
              <span class="input-line-title">收款账户：</span>
              <div>先下单、后选账户<span class="ml-2" style="color:#aaa;">（城市财务账户收款、或者货到付款）</span></div>
            </a-col>
          </a-row>
        </template>
        

        <!-- 收货人 / 取货人 -->
        <template v-if="formState.order_type === 2">
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <span class="input-line-title"> <i class="require-i">*</i>收货地址： </span>
              <a-select class="flex-1" placeholder="请输入所在地址"
                  show-search
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  v-model="formState.consignee_address"
                  @search="handleSearchAddress"
                  @change="handleChangeAddress">
                <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
              </a-select>
              <a-input class="ml-2 flex-1" placeholder="请录入单元/楼层/房号"
                  v-model="formState.detail_address"></a-input>
            </a-col>
          </a-row>
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>收货人： </span>
                <a-input class="flex-1" placeholder="收货人称呼"
                    v-model="formState.consignee_name" />
              </div>
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
                <a-input class="flex-1" placeholder="收货人手机号"
                    v-model="formState.consignee_phone" />
              </div>
              <div class="flex items-center" style="flex:3;">
                <span class="input-line-title"> <i class="require-i">*</i>收货时间： </span>
                <time-picker2 class="flex-1" :duration="60" :value.sync="formState.delivery_time" />
              </div>
            </a-col>
          </a-row>
        </template>
        <template v-if="formState.order_type === 1">
          <a-row class="mt-4">
            <a-col class="input-line flex items-center" :span="24">
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>取货人： </span>
                <a-input class="flex-1" placeholder="取货人称呼"
                    v-model="formState.recipient_name" />
              </div>
              <div class="flex items-center" style="flex:2;">
                <span class="input-line-title"> <i class="require-i">*</i>手机号： </span>
                <a-input class="flex-1" placeholder="取货人手机号"
                    v-model="formState.recipient_phone" />
              </div>
              <div class="flex items-center" style="flex:3;">
                <span class="input-line-title"> <i class="require-i">*</i>取货时间： </span>
                <time-picker2 class="flex-1" :value.sync="formState.pickup_time" />
              </div>
            </a-col>
          </a-row>
        </template>
        <!-- 下单人 -->
        <a-row class="mt-4">
          <a-col class="input-line flex items-center" :span="24">
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"><i class="require-i">*</i>下单人：</span>
              <a-input class="flex-1" placeholder="下单人称呼"
                  v-model="formState.place_order_name" />
            </div>
            <div class="flex items-center" style="flex:2;">
              <span class="input-line-title"><i class="require-i">*</i>手机号：</span>
              <a-input class="flex-1" placeholder="下单人手机号"
                v-model="formState.place_order_phone" />
            </div>
            <div class="flex items-center" style="flex:3;">
              <a-button class="ml-4" type="primary" size="small" 
                  v-if="formState.order_type === 2" 
                  @click="handlerCopyUser">同收货人</a-button>
              <a-button class="ml-4" type="primary" size="small" 
                  v-if="formState.order_type === 1" 
                  @click="handlerCopyUser">同取货人</a-button>
            </div>
          </a-col>
        </a-row>

        <a-row class="mt-4">
          <a-col class="input-line" :span="24">
            <span class="input-line-title">下单备注：</span>
            <a-input class="flex-1" placeholder="会打印在小票上、顾客可见"
                v-model="formState.remarks" />
          </a-col>
        </a-row>
        <a-row class="mt-4">
          <a-col class="input-line" :span="24">
            <span class="input-line-title">内部备注：</span>
            <a-input class="flex-1" placeholder="顾客不可见"
                v-model="formState.private_remarks" />
          </a-col>
        </a-row>

        <a-row class="mt-3">
          <!-- 自提：自提网点 -->
          <a-col v-if="formState.order_type == 1" class="input-line">
            <span class="input-line-title"> <i class="require-i">*</i>自提门店： </span>
            <span class="mr-3">{{ formState.outlets_name || "待选择" }}</span>
            <a-button type="primary" size="small" 
              @click="handlerShowShopList">{{ formState.outlets_name ? "更换" : "选择" }}</a-button>
          </a-col>
          <!-- 配送：供货网点+配送网点 -->
          <a-col v-if="formState.order_type == 2" class="input-line">
            <span class="input-line-title"> <i class="require-i">*</i>配送网点： </span>
            <span class="mr-3">{{ formState.delivery_outlets_name || "无" }}
              <a-button type="link" size="small" class="p-0" 
                @click="handlerShowDlvrOutletsList">{{ formState.delivery_outlets_name ? "更换" : "选择" }}</a-button>
            </span>
            <div class="ml-4" style="color:#aaa;" 
                v-if="formState.delivery_outlets_id">
              （
              <span class="input-line-title">供货门店：</span>
              <span v-if="formState.outlets_name" class="mr-3">{{ formState.outlets_name }}</span>
              <a-button type="link" size="small" class="p-0" 
                @click="handlerShowShopList">{{ formState.outlets_name ? "更换" : "选择" }}</a-button>
              ）
            </div>
          </a-col>
        </a-row>

        <a-row class="mt-3">
          <a-col class="input-line" :span="8">
            <span class="input-line-title"> <i class="require-i">*</i>购买商品： </span>
            <a-button @click="handlerAddGoods" type="primary" size="small">添加</a-button>
          </a-col>
        </a-row>
      </div>

      <base-table
        class="mt-3"
        :customHeight="400"
        ref="orderAddRef"
        :columnsData="add_columns"
        rowKey="sku_id"
        :tableData="goodsList"
      >
        <template #number="{ record }">
          <a-input-number @change="changeGoodNumber" v-model="record.number"></a-input-number>
        </template>
        <template #operation="{ index }">
          <a-button type="link" @click="handlerDeleteSku(index)">删除</a-button>
        </template>
      </base-table>

      <!-- 蛋糕配件 -->
      <div class="mt-6 ml-3" v-if="cakeList.length">
        <span class="text-sm font-bold text-black">蛋糕配件</span>

        <div v-for="(item, i) in cakeList" :key="i * 100000 + item.sku_id">
          <div class="flex mt-2">
            <a-form-model layout="inline" ref="searchRef" class="flex" style="width:100%;">
              <a-form-model-item
                >{{ item.goods_name }}({{ item.flavor_name ? item.flavor_name + "|" : ""
                }}{{ item.specification_name }})</a-form-model-item
              >
              <a-form-model-item prop="birthday_candle" label="蜡烛">
                <birthday-candle :value.sync="item.birthday_candle"></birthday-candle>
              </a-form-model-item>
              <a-form-model-item prop="diners_number" label="餐具数量">
                <a-select
                  :default-value="0"
                  style="width: 100px"
                  v-model="item.diners_number"
                >
                  <a-select-option
                    v-for="(item, index) in tableWare"
                    :value="item"
                    :key="index"
                    >{{ item }}人份</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="生日牌">
                <a-auto-complete
                  v-model="item.birthday_card"
                  :data-source="cardList"
                  style="width: 200px"
                  placeholder="生日牌"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
        
      </div>

      <div class="mt-5 ml-3">
        <div class="flex items-center">
          <span>商品 {{ formState.goods_amount }} 元</span>
          <div class="ml-2" v-if="formState.order_type == 2">
            <span> + 运费 </span>
            <a-input-number v-model="formState.freight_amount" :min="0" :step="1" @change="updateAmount" />
            <span> 元 </span>
          </div>
          <!-- <div class="ml-2">
            <span> - 员工内购优惠 </span>
            <a-input-number v-model="formState.private_offers_amount" :min="0" :step="0.01" @change="updateOffAmount" />
            <span> 元 </span>
          </div> -->
          <div class="ml-2">
            <span> - 其他优惠 </span>
            <a-input-number v-model="formState.other_offers_amount" :min="0" :step="0.01" @change="updateAmount" />
            <span> 元 </span>
          </div>
          <div class="flex items-center">
            <span class="ml-3 mr-3"> = </span>
            <span class="text-lg font-bold">{{ formState.total }}</span>
            <span> 元 </span>
          </div>
        </div>

        <!-- <div class="text-lg">= 订单金额：{{ formState.total }}元</div> -->
        <!-- <div class="mt-2">支付方式：货到付款</div> -->
      </div>
      <div class="mt-5 flex justify-center items-center">
        <a-button @click="handlerCancel" class="mr-8">取消</a-button>
        <a-button class="ml-4" type="primary" @click="handlerCreate">创建</a-button>
        <a-checkbox v-model="ignoreTime" class="ml-3">不校验时间</a-checkbox>
      </div>
    </a-card>

    <select-dlvroutlets-modal 
      v-if="isSelectOutlets" 
      :show.sync="isSelectOutlets" 
      :formData="formState"
      @ok="handleDlvrOutletsChanged"
    ></select-dlvroutlets-modal>
    <select-shop-modal
      v-if="isSelectShop"
      :show.sync="isSelectShop"
      :formData="formState"
      @ok="handleShopChanged"
    ></select-shop-modal>
    <add-goods-modal
      v-if="isSelectGoods"
      :show.sync="isSelectGoods"
      :outletsId="formState.outlets_id"
      :selectRows="goodsList"
      @ok="addGoodsOk"
    ></add-goods-modal>

  </div>
</template>

<script>
import selectDlvroutletsModal from './components/select-dlvroutlets-modal.vue'
import selectShopModal from './components/select-shop-modal.vue'

import { add_columns, candleOptions } from './columns'
import AddGoodsModal from './components/add-goods-modal.vue'

import { getOrderPrice, createOrder, setOrderPayType, getPickUpOutletsList } from '@/api/order/create'
import { getCityList } from "@/api/outlets"
import { isCityManage } from '@/utils/authData.js'

import { tableWare, cardList, subOnAccountPayTypeList } from "@/utils/type.js"
import { getMapPointList, getAdcode, getDistance } from "@/utils/tMap.js"
import { checkAdcodes } from "@/utils/address.js"
import { searchShopDeliveryArea } from "@/utils/delivery.js"
import { validatePhone } from '@/hooks/use-form-rules.js'
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import TimePicker2 from '@/components/order/time-picker2.vue'

export default {
  components: { selectShopModal, selectDlvroutletsModal, AddGoodsModal, TimePicker2, BirthdayCandle },
  data () {
    return {
      cardList,
      tableWare,
      candleOptions,
      subOnAccountPayTypeList,
      add_columns,
      
      addressList: [],
      cityList: [],
      goodsList: [],
      cakeList: [],

      isCityManage: isCityManage(),
      
      formState: {
        order_type: 2, //配送方式
        outlets_name: '',
        goods_amount: 0,
        freight_amount: 0,
        private_offers_amount: 0,
        other_offers_amount: 0,
        city: {
          label: '', // 北京
          value: 0, // 110100000000
        },
        outlets_id: '',
        total: 0,
        delivery_outlets_id: 0,
        delivery_outlets_name: "",
        delivery_outlets_type: "",
        delivery_region_id: 0,
        consignee_lng: "",
        consignee_lat: "",
        consignee_name: "",
        consignee_phone: "",
        // delivery_time_start: "",
        // delivery_time_end: "",
        consignee_address: undefined,
        consignee_adcode: "",
        recipient_name: "",
        recipient_phone: "",
        // pick_up_goods_time_start: "",
        // pick_up_goods_time_end: "",
        place_order_name: "",
        place_order_phone: "",

        place_order_enterprise_name: "",
      },

      // 挂账
      isOnAccount: '',
      subOnAccountType: undefined,
      artificial_pay_flow_no: '',

      isBigEnterprise: false,

      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isSelectOutlets: false,
      isSelectGoods: false,
      isSelectShop: false,

      ignoreTime: false,

      isDeactivated: false,
      
      loading: false,
    }
  },
  watch: {
    // goodsList: {
    //   deep: true,
    //   handler() {
    //     this.computedPrice()
    //   }
    // }
  },
  mounted () {
    this.initCityList()
  },
  activated(){
    if(this.isDeactivated){
      this.isDeactivated = false
      const _this = this
      this.$confirm({
        title: "是否保留上次的表单内容、继续填写？",
        okText: "保留表单",
        cancelText: "清空表单",
        async onOk() {
          
        },
        onCancel() {
          _this.refreshData()
        },
      });
    }
  },
  deactivated(){
    this.isDeactivated = true
  },
  methods: {
    async initCityList () {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    onChangeOrderType(){
      if(this.formState.order_type == 1){
        // 自提不能货到付款（只能挂账）
        this.isOnAccount = 1
        // this.updateOffAmount()
      }
      this.updateAmount()
    },

    // 创建订单提交
    handlerCreate () {
      if(this.loading) return
      // 城市只能是自己下单、不能是关联第三方平台
      if(this.isCityManage){
        this.isOnAccount = 2
        if(!this.isBigEnterprise){
          this.formState.place_order_enterprise_name = ""
        }
      }else{
        this.formState.place_order_enterprise_name = ""
      }
      
      if (this.validateForm()) {
        const params = Object.assign({
          ignore_time: this.ignoreTime ? 2 : ""
        }, this.formState)
        if(this.formState.order_type != 2){
          params.freight_amount = 0
        }
        params.consignee_address = params.consignee_address + (params.detail_address || "")

        // 如果是配送单
        if (this.formState.order_type === 2) {
          params.delivery_time_start = this.formState.delivery_time[0] + ' ' + this.formState.delivery_time[1].split('-')[0]
          params.delivery_time_end = this.formState.delivery_time[0] + ' ' + this.formState.delivery_time[1].split('-')[1]
        } else {
          params.pick_up_goods_time_start = this.formState.pickup_time[0] + ' ' + this.formState.pickup_time[1].split('-')[0]
          params.pick_up_goods_time_end = this.formState.pickup_time[0] + ' ' + this.formState.pickup_time[1].split('-')[1]
        }

        let not_cake_list = this.goodsList.filter(el => el.goods_type != 3)
        params.goods_sku_data = JSON.stringify(not_cake_list.concat(this.cakeList))
        // params.goods_sku_data = JSON.stringify(this.goodsList.map(el => {
        //   if (el.goods_type === 3) {
        //     return {
        //       sku_id: el.sku_id,
        //       price: el.price,
        //       number: el.number,
        //       diners_number: el.diners_number,
        //       birthday_candle: el.birthday_candle,
        //       birthday_card: el.birthday_card
        //     }
        //   } else {
        //     return {
        //       sku_id: el.sku_id,
        //       price: el.price,
        //       number: el.number
        //     }
        //   }
        // }))

        const _this = this
        this.loading = true
        createOrder(params).then(res => {
          this.loading = false

          if (res.code === 0) {
            // 如果创建成功呢，提示并跳转到全部订单页
            if (res.data.submit_result === 'success') {
              let order_no = res.data.success_data.order_no
              // 挂账
              if (_this.isOnAccount == 1) {
                if(_this.isCityManage){
                  this.$message.info('城市不能使用第三方挂账')
                  this.onCompleteAndJump(order_no)
                }else{
                  setOrderPayType({
                    order_no: order_no,
                    pay_type: 30,
                    pay_subtype: _this.subOnAccountType,
                    financial_account_id: 1, // 挂账写死科技公司
                    artificial_pay_flow_no: _this.artificial_pay_flow_no,
                  }).then(r => {
                    if (r.code == 0) {
                      this.$message.success('下单成功')
                    }
                    this.onCompleteAndJump(order_no)
                  })
                } 
              } else {
                // 非挂账
                // if(_this.isCityManage){ // 城市客服：去订单页 完成支付
                //   this.$message.success('下单成功，请尽快完成支付')
                //   this.handlerJump(order_no)
                // }else{ // 总部客服：不收款、全都货到付款（涉及到子支付方式）
                //   setOrderPayType({
                //     order_no: order_no,
                //     financial_account_id: 1, // 写死科技公司
                //     pay_type: 10,
                //     pay_subtype: ????
                //   }).then(r => {
                //     if (r.code == 0) {
                //       this.$message.success('下单成功')
                //     }
                //     this.handlerJump(order_no)
                //   }).catch(()=>{
                //     this.handlerJump(order_no)
                //   })
                // }
                this.$message.success('下单成功，请尽快完成支付')
                this.onCompleteAndJump(order_no)
              }
            } else {
              this.$message.error(res.data.error_data.error_message)
              if (res.data.error_data.error_type === 3) {
                this.computedPrice()
              }
            }
          }
        })
      }
    },

    onCompleteAndJump(orderId){
      this.ignoreTime = false
      // this.$store.commit('tabsView/closeCurrentTab', this.$route)
      // this.$router.push({ path: '/order-detail', query: { order_no: orderId } })
      
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { 
          order_no: orderId,
        },
      });

      const _this = this
      setTimeout(()=>{
        window.open(newPage.href, "_blank");
        _this.$confirm({
          title: "提交订单成功！",
          okText: "保留表单",
          cancelText: "清空表单",
          async onOk() {
            
          },
          onCancel() {
            _this.refreshData()
          },
        });
      }, 800)
    },
    // handlerJump (orderId) {
    //   this.$store.commit('tabsView/closeCurrentTab', this.$route)
    //   this.$router.push({ path: '/order-detail', query: { order_no: orderId } })
    // },

    handlerShowShopList () {
      if (this.formState.order_type === 2) {
        if (this.formState.consignee_lat && this.formState.consignee_lng) {
          this.isSelectShop = true
        } else {
          // 应该根据adcode算
          this.$message.warning('请先输入收货地址')
        }
      } else {
        this.isSelectShop = true
      }
    },
    handleShopChanged () {
      this.computedPrice()
    },
    handlerShowDlvrOutletsList () {
      if (this.formState.order_type === 2) {
        if (this.formState.consignee_lat && this.formState.consignee_lng) {
          this.isSelectOutlets = true
        } else {
          // 应该根据adcode算
          this.$message.warning('请先输入收货地址')
        }
      }
    },
    handleDlvrOutletsChanged () {
      // 如果由门店配送：供货网点改成该门店
      if(this.formState.delivery_outlets_type == 1){
        this.formState.outlets_id = this.formState.delivery_outlets_id
        this.formState.outlets_name = this.formState.delivery_outlets_name
        this.handleShopChanged()
      }else{
        this.updateAmount()
      }
    },
    
    async autoFigureShop(){
      // 重置
      this.formState.outlets_id = ""
      this.formState.outlets_name = ""
      this.formState.outlets_adcode = ""

      if(this.formState.delivery_outlets_id){
        this.formState.outlets_adcode = this.formState.consignee_adcode
        
        // 如果配送网点是门店、下单门店直接就用这个
        if(this.formState.delivery_outlets_type==1){
          this.formState.outlets_id = this.formState.delivery_outlets_id
          this.formState.outlets_name = this.formState.delivery_outlets_name
          // this.formState.outlets_adcode = this.formState.consignee_adcode
          this.handleShopChanged()
        }else{
        // 如果配送网点是配送站、算一个下单门店
          this.formState.outlets_name = "计算中..."

          let { code, data } = await getPickUpOutletsList({
            adcode: this.formState.consignee_adcode
          })
          this.formState.outlets_name = ""

          if(code == 0){
            let arr = data.list.map(item => {
              item.distance = getDistance(
                this.formState.consignee_lat, 
                this.formState.consignee_lng,
                item.outlets_lat_lng.split(' ')[1], 
                item.outlets_lat_lng.split(' ')[0]
              ).toFixed(2)
              return item
            })
            arr.sort((a,b)=>{return a.distance-b.distance})
            const shop = arr && arr[0]
            if(shop){
              this.formState.outlets_id = shop.outlets_id
              this.formState.outlets_name = shop.outlets_name
              // this.formState.outlets_adcode = this.formState.consignee_adcode

              this.handleShopChanged()
            }
          }
        } 
      }
    },

    handlerAddGoods () {
      if (this.formState.outlets_id) {
        this.isSelectGoods = true
      } else {
        // 没选门店 先选择门店
        if(this.formState.order_type == 1){
          this.isSelectShop = true
        }
        if(this.formState.order_type == 2){
          this.$message.info('请先输入收货地址 计算配送网点')
        } 
      }
    },
    // 添加商品
    addGoodsOk (rows) {
      if(rows.some(el=>el.goods_type==4)){
        this.$message.warning('不能选择好茶')
        return
      }
      this.goodsList = rows.map(el => {
        if (!el.number) {
          el.number = 1
        }
        // 如果添加的有蛋糕，则放入蛋糕列表一份
        if (el.good_type === 3) {
          el.diners_number = 0
          el.birthday_candle = null
          el.birthday_card = ''
        }
        if (el.stock < 0) {
          el.status = "无货"
        } else if (el.stock < el.number) {
          el.status = "库存不足"
        }
        return el
      })
      this.updateCakeList()
      this.computedPrice()
    },

    changeGoodNumber () {
      this.updateCakeList()
      this.computedPrice()
    },

    // 删除
    handlerDeleteSku (index) {
      this.goodsList.splice(index, 1)
      this.updateCakeList()
      this.computedPrice()
    },

    updateCakeList () {
      let cakes = []
      this.goodsList.map(el => {
        if (el.goods_type == 3) {
          let arr = this.cakeList.filter(ck => ck.sku_id == el.sku_id)
          for (let i = 0; i < el.number; i++) {
            let cake = arr[i] || {}
            let item = Object.assign({}, el)
            Object.assign(item, {
              number: 1,
              diners_number: cake.diners_number,
              birthday_candle: cake.birthday_candle,
              birthday_card: cake.birthday_card,
            })
            cakes.push(item)
          }
        }
      })
      this.cakeList = cakes
    },

    // 计算订单价格
    computedPrice () {
      if (!this.formState.outlets_id) return;
      if (!this.goodsList.length) {
        this.formState.goods_amount = 0
        this.updateAmount()
        return
      }

      // 添加完商品后，进行订单的价格计算
      const params = {
        outlets_id: this.formState.outlets_id,
        goods_sku_data: JSON.stringify(this.goodsList.map(el => {
          return {
            sku_id: el.sku_id,
            number: el.number || 1
          }
        }))
      }
      getOrderPrice(params).then(res => {
        if (res.code == 0) {
          let okHaxe, arr
          if (res.data.submit_result === 'error') {
            okHaxe = {}
            arr = res.data.error_data.goods_sku_list || []
            arr.map(el => {
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el => {
              if (okHaxe[el.sku_id]) {
                el.status = ""
                el.stock = okHaxe[el.sku_id].stock
                if (el.stock < 0) {
                  el.status = "无货"
                } else if (el.stock < el.number) {
                  el.status = "库存不足"
                }
              } else {
                el.status = "无货"
              }
              return el
            })
            this.$message.error(res.data.error_data.error_message)
            // return
          } else if (res.data.submit_result === 'success') {
            okHaxe = {}
            arr = res.data.success_data.goods_sku_list || []
            arr.map(el => {
              okHaxe[el.sku_id] = el
            })
            this.goodsList.map(el => {
              if (okHaxe[el.sku_id]) {
                el.stock = okHaxe[el.sku_id].stock
                el.price = okHaxe[el.sku_id].price
                el.status = ""
              }
              return el
            })

            this.formState.goods_amount = res.data.success_data.goods_amount
            this.updateAmount()
          }
        }
      })
    },

    updateAmount(){
      this.formState.total = (
        parseFloat(this.formState.goods_amount)
        + (this.formState.order_type==2 ? parseFloat(this.formState.freight_amount) : 0)
        - parseFloat(this.formState.private_offers_amount)
        - parseFloat(this.formState.other_offers_amount)
      ).toFixed(2);
    },
    updateOffAmount(){
      // if(this.isOnAccount != 1){
      // if(this.isCityManage){
      //   this.formState.other_offers_amount = 0
      // }
      // this.formState.private_offers_amount = 0
      
      this.updateAmount()
    },

    handlerCopyUser(){
      if(this.formState.order_type == 1){
        this.formState.place_order_name = this.formState.recipient_name
        this.formState.place_order_phone = this.formState.recipient_phone
      }
      if(this.formState.order_type == 2){
        this.formState.place_order_name = this.formState.consignee_name
        this.formState.place_order_phone = this.formState.consignee_phone
      }
      this.$forceUpdate()
    },

    // 地址输入
    handleSearchAddress (value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item => {
            item.region = item.province + item.city + item.district
            return item
          })
        }
      })
    },

    async handleChangeAddress (value) {
      const item = this.addressList.find((item) => item.id === value)
      this.formState.consignee_address = item.address + item.title
      this.formState.consignee_lat = item.location.lat
      this.formState.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)
      if (adcodesRes && adcodesRes.status == 0) {
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          this.formState.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
        } else {
          this.formState.consignee_adcode = adcodesRes.result.ad_info.adcode
        }
        for (let i in this.cityList) {
          let v = checkAdcodes(this.formState.consignee_adcode, this.cityList[i].adcode)
          if (v == 1 || v == 0) {
            this.formState.city.label = this.cityList[i].name
            this.formState.city.value = this.cityList[i].adcode
            break
          }
        }
        // 计算配送点
        let area = await searchShopDeliveryArea({
          lat: this.formState.consignee_lat,
          lng: this.formState.consignee_lng
        }, this.formState.consignee_adcode)
        if (area) {
          this.formState.delivery_outlets_id = area.outlets_id
          this.formState.delivery_outlets_name = area.outlets_name
          this.formState.delivery_outlets_type = area.outlets_type
          this.formState.delivery_region_id = area.delivery_region_id
          this.formState.freight_amount = area.freight
          // this.computedPrice()
        } else {
          this.formState.delivery_outlets_id = 0
          this.formState.delivery_outlets_name = ''
          this.formState.delivery_outlets_type = ''
          this.formState.delivery_region_id = '';
          this.$message.warning("超区，不在配送范围内");
        }

        this.autoFigureShop()
      }

    },

    validateForm () {
      if (this.isOnAccount == '') {
        this.$message.warning('请选择订单来源')
        return false
      }
      if (this.isOnAccount == 1) {
        if (!this.subOnAccountType) {
          this.$message.warning('请选择挂账平台')
          return false
        }
        if (!this.artificial_pay_flow_no) {
          this.$message.warning('请填写第三方平台流水号')
          return false
        }
      }

      if (this.formState.place_order_phone && !validatePhone(this.formState.place_order_phone)) {
        this.$message.warning('下单人手机号格式有误')
        return false
      }
      // 如果是自提
      if (this.formState.order_type === 1) {
        if (!this.formState.recipient_name) {
          this.$message.warning('请输入取货人姓名')
          return false
        }
        if (!this.formState.recipient_phone) {
          this.$message.warning('请输入取货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.recipient_phone)) {
            this.$message.warning('取货人手机号格式有误')
            return false
          }
        }
        if (!this.formState.pickup_time || !this.formState.pickup_time.length) {
          this.$message.warning('请选择取货时间')
          return false
        }
      }
      if (this.formState.order_type === 2) {
        if (!this.formState.consignee_name) {
          this.$message.warning('请输入收货人姓名')
          return false
        }
        if (!this.formState.consignee_phone) {
          this.$message.warning('请输入收货人手机号')
          return false
        } else {
          if (!validatePhone(this.formState.consignee_phone)) {
            this.$message.warning('收货人手机号格式有误')
            return false
          }
        }
        if (!this.formState.delivery_time || !this.formState.delivery_time.length) {
          this.$message.warning('请选择配送时间')
          return false
        }
        if (!this.formState.consignee_lat) {
          this.$message.warning('请输入收货地址')
          return false
        }
      }
      // 校验蛋糕数据
      // const cakes = this.cakeList
      // if (cakes && cakes.length) {
      //   for (let i = 0; i < cakes.length; i++) {
      //     if (!cakes[i].birthday_candle) {
      //       this.$message.warning('请选择蛋糕蜡烛')
      //       return false
      //     }
      //   }
      // }
      if (this.goodsList.length === 0) {
        this.$message.warning('请至少选择一个商品')
        return false
      }
      if (!this.formState.outlets_id) {
        this.$message.warning('请选择负责网点')
        return false
      }
      if(!Number(this.formState.total)>0){
        this.$message.warning('订单总金额不正确')
        return false
      }

      return true
    },

    handlerCancel () {
      this.refreshData()
    },
    refreshData(){
      this.addressList = []
      this.cityList = []
      this.goodsList =[]
      this.cakeList =[]
      
      this.formState = {
        order_type: 2, //配送方式
        outlets_name: '',
        freight_amount: 0,
        private_offers_amount: 0,
        other_offers_amount: 0,
        city: {
          label: '', // 北京
          value: 0, // 110100000000
        },
        outlets_id: '',
        total: 0,
        delivery_outlets_id: 0,
        delivery_outlets_name: "",
        delivery_outlets_type: "",
        delivery_region_id: 0,
        consignee_lng: "",
        consignee_lat: "",
        delivery_time_start: "",
        delivery_time_end: "",
        consignee_name: "",
        consignee_phone: "",
        consignee_address: undefined,
        consignee_adcode: "",
      }

      // 挂账
      this.isOnAccount = ''
      this.subOnAccountType = undefined
      this.artificial_pay_flow_no = ''

      this.isSelectOutlets = false
      this.isSelectGoods = false
      this.isSelectShop = false

      this.isDeactivated = false
    },
  }
}
</script>

<style lang="less">
.input-line {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .require-i {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
}

.input-line-title {
  width: 100px;
  text-align: right;
}

.addr-region {
  margin-left: 10px;
  color: #ccc;
}

.thingtohide {
  animation: cssAnimation 0s ease-in 2s forwards;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
</style>