<template>
  <a-modal
    :visible.sync="show"
    title="选择门店"
    destroyOnClose
    width="800px"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <!-- <div class="modal-search-bar mb-2" v-if="formData.order_type == 1"> -->
    <div class="modal-search-bar flex mb-2">
      <a-select class="mr-2"
        ref="selectCity"
        v-model="searchForm.adcode"
        style="width:100px;"
        placeholder="城市"
        @change="onChangeCity"
      >
        <a-select-option
          v-for="(item, index) in cityList"
          :key="index"
          :label="item.name"
          :value="item.adcode"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-input class="mr-2" style="width:200px;" placeholder="网点名称"
        allowClear 
        v-model="searchName"
        @keyup.enter="searchShop"></a-input>
      <a-select
        show-search
        allowClear
        v-model="address"
        placeholder="输入地址可以搜索附近门店"
        style="flex:1;"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handleSearchAddress"
        @change="handleChangeAddress"
      >
        <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
      </a-select>
      <a-button class="ml-4" @click="searchShop" type="primary">搜索</a-button>
    </div>
    <base-table
      ref="goodsTable"
      :customHeight="400"
      :columnsData="columns"
      rowKey="outlets_id"
      :tableData="tableData"
    >
      <template #operation="{ record }">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
import { getCityList } from "@/api/outlets"
import { getPickUpOutletsList } from "@/api/order/create"
import { getMapPointList } from "@/utils/tMap.js"
import { checkAdcodes } from "@/utils/address.js"

import { getDistance } from "@/utils/tMap.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    formData: {
      type: Object,
      default: () => { }
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      searchForm: {
        adcode: undefined,
      },
      searchName: undefined,
      address: undefined,
      addressInfo: {},
      columns: [
        {
          title: "门店名",
          dataIndex: "outlets_name",
          align: "left",
          width: 100,
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "left",
          width: 130,
        },
        {
          title: "地址",
          dataIndex: "address",
          align: "left",
          // width: "45%",
        },
        {
          title: "距离",
          dataIndex: "distance",
          align: "center",
          width: 100,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => (val?val+'km':""),
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 70,
          slots: {
            customRender: "operation",
          },
        },
      ],
      cityList: [],
      tableData: [],
      baseList: [],
      addressList: [],
      total: 0,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      console.log("initCityList formData", this.formData)

      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
        let cityAdcode
        // 收货地址-->城市门店
        if(this.formData.city && this.formData.city.value){
          cityAdcode = this.formData.city.value
        }
        if(!cityAdcode && this.formData.consignee_adcode){
          for(let i in this.cityList){
            let v = checkAdcodes(this.formData.consignee_adcode, this.cityList[i].adcode)
            if(v == 1 || v == 0){
              cityAdcode = this.cityList[i].adcode
              break
            }
          }
        }

        // test 之前选的门店-->城市门店
        if (this.formData.outlets_adcode) {
          for(let i in this.cityList){
            let v = checkAdcodes(this.formData.outlets_adcode, this.cityList[i].adcode)
            if(v == 1 || v == 0){
              cityAdcode = this.cityList[i].adcode
              break
            }
          }
        }
        this.searchForm.adcode = cityAdcode

        // 配送-收货地址预置
        if(this.formData.order_type == 2){
          if(this.formData.consignee_lat){
            this.addressInfo = {
              location: {
                lat: this.formData.consignee_lat,
                lng: this.formData.consignee_lng,
              },
              address: this.formData.consignee_address,
              adcode: cityAdcode
            }
          }
        }

        // 检索门店 展示
        if(this.searchForm.adcode){
          this.searchShop()
        }
      }
    },
    async searchShop() {
      if(!this.searchForm.adcode){
        this.$message.warning('请选择城市')
        return
      }
      if(!this.baseList.length){
        let res = await getPickUpOutletsList(this.searchForm)
        this.baseList = res.data.list
      }

      let arr = this.baseList;
      if(this.searchName){
        arr = arr.filter(el=>{
          return el.outlets_name.indexOf(this.searchName) > -1
        })
      }
      if(this.addressInfo && this.addressInfo.location){
        arr = arr.map(item => {
          item.distance = getDistance(
            this.addressInfo.location.lat, this.addressInfo.location.lng,
            item.outlets_lat_lng.split(' ')[1], item.outlets_lat_lng.split(' ')[0]
          ).toFixed(2)
          return item
        })
        arr.sort((a,b)=>{return a.distance-b.distance})
      }
      
      this.tableData = arr
    },
    // 地址输入
    handleSearchAddress(value) {
      getMapPointList(value, this.$refs.selectCity.label).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+(item.city || '')+(item.district || '')
            return item
          })
        }
      })
    },

    async handleChangeAddress(value) {
      let item
      if(value){
        item = this.addressList.find((item) => item.id === value)
      }
      if(item){
        this.addressInfo = item
        this.address = item.address + item.title
      }else{
        this.addressInfo = null
      }

      this.searchShop()
    },

    handlerSelect(row) {
      console.log("this.searchForm.adcode", this.searchForm.adcode)

      this.formData.outlets_id = row.outlets_id
      this.formData.outlets_name = row.outlets_name
      this.formData.outlets_adcode = this.searchForm.adcode

      this.$emit("ok", row)
      this.$emit("update:show", false)
    },

    onChangeCity(){
      this.baseList = []
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
.addr-region{
  margin-left: 10px;
  color: #ccc;
}
</style>