<template>
  <a-modal
    :visible.sync="show"
    title="选择配送网点"
    destroyOnClose
    width="800px"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <base-table
      ref="goodsTable"
      :rowClassName="getRowClass"
      :customHeight="300"
      :columnsData="columns"
      rowKey="outlets_id"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #operation="{ record }">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
// import { getCityList } from "@/api/outlets"
import { getDeliveryOutletsList, getPickUpOutletsList } from "@/api/order/create"
import { getDistance, IsPtInPoly } from "@/utils/tMap.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    formData: {
      type: Object,
      default: () => { }
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      adcode: '',
      searchForm: {
        outlets_name: "",
        page: 1,
        page_count: 10,
      },
      allColumns: [
        {
          title: "网点名称",
          dataIndex: "outlets_name",
          align: "center",
          // width: 140,
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "center",
          // width: 130,
        },
        {
          title: "网点类型",
          dataIndex: "outlets_type",
          align: "center",
          width: 80,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => this.formatType(val),
        },
        {
          title: "覆盖范围",
          dataIndex: "cover_name",
          align: "center",
          width: 80,
        },
        {
          title: "有库存",
          dataIndex: "have_stock",
          align: "center",
          width: 70,
        },
        {
          title: "距离",
          dataIndex: "distance",
          align: "center",
          width: 80,
          slots: {
            customRender: "distance",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val+" km" },
        },
        {
          title: "运费",
          dataIndex: "freight",
          align: "center",
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 70,
          slots: {
            customRender: "operation",
          },
        },
      ],
      columns: [],
      cityList: [],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    // console.log(this.formData.city)
    // this.initCityList()
    console.log("mounted formData", this.formData)

    let adcode = this.formData.city && this.formData.city.value
    if(!adcode){
      adcode = this.formData.consignee_adcode
    }
    this.adcode = adcode

    this.initData()
  },
  methods: {
    formatType(val) {
      return val === 1 ? '门店' : '配送站'
    },
    // async initCityList() {
    //   const { data, code } = await getCityList()
    //   if (code == 0) {
    //     this.cityList = data.list
    //     if (this.formData.outlets_adcode) {
    //       this.adcode = this.formData.outlets_adcode
    //       this.initData()
    //     }
    //   }
    // },
    async initData() {
      let res = ''
      // 如果是配送单
      if (this.formData.order_type === 2) {
        if(this.formData.consignee_lat){
          this.columns = this.allColumns.filter(el=>!['have_stock'].includes(el.dataIndex))
        }else{
          this.columns = this.allColumns.filter(el=>!['distance','have_stock'].includes(el.dataIndex))
        }
        res = await getDeliveryOutletsList({ adcode: this.adcode })
      } else {
        if(this.formData.consignee_lat){
          this.columns = this.allColumns
        }else{
          this.columns = this.allColumns.filter(el=>!['distance'].includes(el.dataIndex))
        }
        await getPickUpOutletsList(this.searchForm)
      }
      // test 这块有问题
      const areaList = this._getDeliveryArea(res.data.list, {
        lat: this.formData.consignee_lat,
        lng: this.formData.consignee_lng
      })

      this.tableData = areaList.map(item => {
        item.cover_name = item.is_cover === 2 ? '是' : '否'
        item.have_stock = item.have_stock === 1 ? '否' : '是'

        return item
      })
    },
    // 保存
    handlerSearch() {
      this.tableData = this.baseList.filter(item => item.outlets_name.includes(this.searchForm.outlets_name))
    },
    handlerSelect(row) {
      const areas = this._getDeliveryAreaPOI(row, {
        lat: this.formData.consignee_lat,
        lng: this.formData.consignee_lng
      })
      if (areas) {
        this.formData.delivery_outlets_id = row.outlets_id
        this.formData.delivery_outlets_name = row.outlets_name
        this.formData.delivery_outlets_type = row.outlets_type
        this.formData.delivery_region_id = areas.delivery_region_id
        this.formData.freight_amount = areas.freight
        this.$emit("ok", row)
        this.$emit("update:show", false)
      } else {
        this.$confirm({
          title: "温馨提示",
          content: '收货地址不在所选网点的配送范围内，确认选择？',
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.formData.delivery_outlets_id = row.outlets_id
            this.formData.delivery_outlets_name = row.outlets_name
            this.formData.delivery_outlets_type = row.outlets_type
            this.formData.delivery_region_id = ''
            this.formData.freight_amount = 0
            this.$emit("ok", row)
            this.$emit("update:show", false)
          }
        })
      }

    },

    getRowClass(record) {
      if (record.is_cover === 2) {
        return 'cover-status'
      } else {
        return 'cover-disable-status'
      }
    },

    // 多网点（含配送范围） --> 能配送的网点（含配送范围）
    _getDeliveryArea(outletsList, poi) {
      let list = outletsList || [];
      let areaList = [];
      for (let i = 0; i < list.length; i++) {
        const outlets = list[i]
              outlets.is_cover = 1
        if(poi.lat){
          outlets.distance = getDistance()
          outlets.distance = getDistance(
            poi.lat, 
            poi.lng,
            outlets.outlets_lat_lng.split(' ')[1], 
            outlets.outlets_lat_lng.split(' ')[0]
          ).toFixed(2)
        }
        for (let j = 0; j < outlets.delivery_region_list.length; j++) {
          let area = outlets.delivery_region_list[j];
              area.outlets_id = outlets.outlets_id
              area.outlets_name = outlets.outlets_name
              area.outlets_type = outlets.outlets_type
              area.is_delivery = outlets.is_delivery
              area.index = i
          areaList.push(area)
        }
      }
      areaList = areaList.sort((a, b) => a.freight - b.freight);
      for (let i = 0; i < areaList.length; i++) {
        let region = areaList[i].region;
        let tmp = region.split(",");
        let pointList = tmp.map(el => {
          let t = el.split(' ');
          return {
            latitude: Number(t[1]),
            longitude: Number(t[0])
          }
        })
        if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
          list[areaList[i].index].is_cover = 2
          list[areaList[i].index].freight = areaList[i].freight
        }
      }
      list = list.sort((a, b) => {
        if(a.is_cover == b.is_cover){
          if(a.distance){
            return a.distance - b.distance
          }
          return 0
        }
        return b.is_cover - a.is_cover
      });

      return list;
    },


    // // 多网点（含配送范围） --> 能配送的网点（含配送范围）
    _getDeliveryAreaPOI(outlets, poi) {
      let areaList = outlets.delivery_region_list;

      for (let i = 0; i < areaList.length; i++) {
        let region = areaList[i].region;
        let tmp = region.split(",");
        let pointList = tmp.map(el => {
          let t = el.split(' ');
          return {
            latitude: Number(t[1]),
            longitude: Number(t[0])
          }
        })
        if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
          return areaList[i]
        }
      }
      return null;
    },
  },
}
</script>

<style lang="less">
.ant-modal-body {
  position: relative;
  // min-height: 300px;
  margin-bottom: 10px;
}
.cover-disable-status {
  // background: rgb(205, 205, 205);
  // opacity: 0.4;
  color: #ccc;
  // &:hover {
  //   background: rgb(205, 205, 205);
  //   opacity: 0.8;
  // }
}
  
</style>